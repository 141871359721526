import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { testAttributes } from '../Constants';
import {
    articleContainer,
    cardContent,
    gatsbyImg,
    mainCategory,
    onlyCategory,
} from './HeroCard.module.scss';
import ReadingTime from '../../../simple/ReadingTime';

const HeroCard = ({ featuredCard, isMainCategory }) => (
    <div
        className={`${articleContainer}`}
        data-test={testAttributes.HEROCARD_CONTAINER}
    >
        <a href={featuredCard.url} data-test={testAttributes.HEROCARD_LINK}>
            {featuredCard.image && (
                <GatsbyImage
                    className={gatsbyImg}
                    image={featuredCard.image.data}
                    alt={featuredCard.image.alt}
                    data-test={testAttributes.HEROCARD_IMAGE}
                />
            )}
        </a>
        <div
            className={`${cardContent} ${
                isMainCategory ? mainCategory : onlyCategory
            }`}
            data-test={testAttributes.HEROCARD_CONTENT}
        >
            <h4 data-test={testAttributes.HEROCARD_CONTENT_TITLE}>
                <a href={featuredCard.url}>{featuredCard.title}</a>
            </h4>
            <div>
                <span data-test={testAttributes.HEROCARD_CONTENT_AUTHOR}>
                    {`${featuredCard.author}`}
                </span>
                <span data-test={testAttributes.HEROCARD_CONTENT_READTIME}>
                    <ReadingTime minutes={featuredCard.minutes} />
                </span>
            </div>
        </div>
    </div>
);
HeroCard.propTypes = {
    isMainCategory: PropTypes.bool,
    featuredCard: PropTypes.objectOf(PropTypes.any),
};

HeroCard.defaultProps = {
    featuredCard: {},
    isMainCategory: true,
};
export default HeroCard;
