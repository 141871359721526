// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    HEROLINK_CONTAINER: 'herolink_container',
    HEROLINK_INFOCONTAINER: 'herolink_infocontainer',
    HEROLINK_INFONAME: 'herolink_infotitle',
    HEROLINK_ICON: 'herolink_icon',
};
export const defaultAttributes = {};
export const sampleAttributes = {
    dataLink: {
        title: 'Buying a Home',
        subtitle: 'Learn About Buying a Home',
        url: '',
    },
    listLinks: [
        {
            title: 'Buying a Home',
            subtitle: 'Learn About Buying a Home',
            url: '',
        },
        {
            title: 'Owning a Home',
            subtitle: 'Learn About Owning a Home',
            url: '',
        },
        {
            title: 'Selling a Home',
            subtitle: 'Learn AboutSelling a Home',
            url: '',
        },
    ],
};
