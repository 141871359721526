// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var content = "k_h";
export var breadcrumbContainer = "k_j";
export var breadcrumb = "k_k";
export var CategoryComponent = "k_F";
export var categoryReadMore = "k_G";
export var titlePostRelated = "k_m";
export var gridContainerPostRelated = "k_D";