import { navigate } from 'gatsby';
import useLocation from '../useLocation';
import { page1Search } from './Constants';

const handlePageChange = ({selected}) =>
{
    const currentURL = useLocation();
    const { pathname, search, hash } = currentURL;
    let pagePart; let navSearch;
    const basePath = pathname.replace(/\/page\/\d+\/?$/, '/').replace(/\/?$/, '/');
    if ( selected > 0 )
    {
        pagePart = `page/${ parseInt( selected, 10 ) + 1}/`;
        navSearch = ( search !== page1Search ) ? search : '';
    }
    else
    {
        pagePart = '';
        navSearch = page1Search
    }
    navigate( `${basePath}${pagePart}${navSearch}${hash}` )
}

export default handlePageChange;