// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var articleContainer = "P_bh";
export var cardContent = "P_bk";
export var gatsbyImg = "P_bj";
export var mainCategory = "P_bg";
export var onlyCategory = "P_cG";