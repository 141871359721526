import React from 'react';
import PropTypes from 'prop-types';

import { testAttributes } from './Constants';
import { subcategory, subcategoryIcon } from './HeroLink.module.scss';
/**
 Component that allows display a link from hero section category ).
 */
const HeroLink = ({ title, subtitle, url }) => (
    <a
        className={subcategory}
        href={url}
        data-test={testAttributes.HEROLINK_CONTAINER}
    >
        <div data-test={testAttributes.HEROLINK_INFOCONTAINER}>
            <h3 data-test={testAttributes.HEROLINK_INFONAME}>{title}</h3>
            <p>{subtitle}</p>
        </div>
        <div
            className={subcategoryIcon}
            data-test={testAttributes.HEROLINK_ICON}
        />
    </a>
);
HeroLink.propTypes = {
    /** title, link subtitle  */
    title: PropTypes.string.isRequired,
    /** subtitle, link subtitle */
    subtitle: PropTypes.string.isRequired,
    /** url, link url */
    url: PropTypes.string.isRequired,
};

HeroLink.defaultProps = {};
export default HeroLink;
