import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from '../Constants';
import {
    headerCardTitle,
    headerCardDescription,
} from './HeroTitle.module.scss';

const HeroTitle = ({ title, description }) => (
    <>
        <h1
            data-test={testAttributes.HEROTITLE_TITLE}
            className={headerCardTitle}
        >
            {title}
        </h1>
        {description && (
            <p
                data-test={testAttributes.HEROTITLE_DESCRIPTION}
                className={headerCardDescription}
            >
                {description}
            </p>
        )}
    </>
);
HeroTitle.propTypes = {
    /** title, Title of content of the tooltip */
    title: PropTypes.string,
    /** title, Title of content of the tooltip */
    description: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
        PropTypes.object,
    ]),
};

HeroTitle.defaultProps = {
    title: '',
    description: '',
};
export default HeroTitle;
