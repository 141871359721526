import * as HeroSectionConstants from '../../composite/HeroSection/Constants';
import * as HeroLinkConstants from '../../composite/HeroLink/Constants';
import * as GridContainerConstants from '../../composite/GridContainer/Constants';

import * as HeaderConstants from '../../composite/Header/Constants';
import * as FooterConstants from '../../composite/Footer/Constants';

export const testAttributes = {
    CATEGORYPAGE_MAIN: 'categorypage_main',
    CATEGORYPAGE_BREADCRUMBS: 'categorypage_breadcrumbs',
    CATEGORYPAGE_HEROSECTION: 'categorypage_herosection',
    CATEGORYPAGE_TITLERELATED: 'categorypage_titlerelated',
    CATEGORYPAGE_GRIDCONTAINER: 'categorypage_gridcontainer',
};

const breadcrumbs = [
    {
        id: 10802,
        term_id: null,
        text: 'Home',
        url: 'https://dev.lowermybills.com/',
    },
    {
        id: 11802,
        term_id: null,
        text: 'Personal Loans',
        url: 'https://dev.lowermybills.com/personal-loans/',
    },

    {
        id: 14984,
        term_id: null,
        text: 'How To Get A Loan With Bad Credit',
        url:
            'https://dev-lmb.pantheonsite.io/personal-loans/how-to-get-a-loan-with-bad-credit/',
    },
];
export const sampleAttributes = {
    infoCategory: {
        infoSection: HeroSectionConstants.sampleAttributes.infoCategory,
        postsRelated: GridContainerConstants.gridParams.samplePosts,
        breadcrumbs,
        titleRelated: 'Featured Articles',
        btnLabel: 'SEE MORE',
        listLinks: HeroLinkConstants.sampleAttributes.listLinks,
    },
    infoHome: {
        infoSection: HeroSectionConstants.sampleAttributes.infoHome,
        postsRelated: GridContainerConstants.gridParams.samplePosts,
        breadcrumbs,
        titleRelated: 'Featured Articles',
        btnLabel: 'SEE MORE',
        listLinks: [],
    },
    headerProps: {
        headerMenu: HeaderConstants.sampleAttributes.headerMenu,
        logoUrl: HeaderConstants.defaultAttributes.url,
        logoAlt: HeaderConstants.defaultAttributes.alt,
    },
    footerProps: {
        itemsMenu: FooterConstants.sampleAttributes.listMenu,
        socialLinks: FooterConstants.sampleAttributes.links,
    },
};
