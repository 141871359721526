import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from './Constants';
import {
    CategoryHeroComponent,
    heroElements,
    mainCategory,
    articleContainer,
    subcategoryLinks,
} from './HeroSection.module.scss';
import HeroTitle from './HeroTitle';
import HeroCard from './HeroCard';
/**
 Component that allows displaying the hero section  using 3 subcomponent ( HetoTitle,HeroCard,HeroSubcategory  ).
 */
const HeroSection = ({
    infoSection,
    isMainCategory,
    isFeaturedManually,
    children,
}) => (
    <div
        className={`${CategoryHeroComponent} hero-elements ${
            isFeaturedManually ? 'feature-post-selected' : 'feature-post-auto'
        }  `}
        data-test={testAttributes.HEROSECTION_CONTAINER}
    >
        <HeroTitle
            title={infoSection.title}
            description={infoSection.description}
            className={articleContainer}
            data-test={testAttributes.HEROSECTION_TITLE}
        />

        <div
            className={`${heroElements} ${isMainCategory ? mainCategory : ''}`}
            data-test={testAttributes.HEROSECTION_CONTENT}
        >
            <div
                className={articleContainer}
                data-test={testAttributes.HEROSECTION_CONTENT_CARD}
            >
                <HeroCard
                    featuredCard={infoSection.featuredCard}
                    isMainCategory={isMainCategory}
                />
            </div>
            {isMainCategory && (
                <div
                    className={subcategoryLinks}
                    data-test={testAttributes.HEROSECTION_CONTENT_SUBCATEGORY}
                >
                    {children}
                </div>
            )}
        </div>
    </div>
);
HeroSection.propTypes = {
    /** isMainCategory, Defined if the card hero has a subcategory */
    isMainCategory: PropTypes.bool,
    /** isFeaturedManually, Defined if the featured is manual or automatic */
    isFeaturedManually: PropTypes.bool,
    /** infoSection, Data from controller to show in HeroSection */
    infoSection: PropTypes.objectOf(PropTypes.any),
    /** children, list of Links related with the category */
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.elementType,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

HeroSection.defaultProps = {
    isMainCategory: true,
    isFeaturedManually: true,
    infoSection: {},
    children: '',
};
export default HeroSection;
