// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var content = "t_h";
export var CategoryHeroComponent = "t_bc";
export var subcategoryLinks = "t_bd";
export var heroElements = "t_bf";
export var mainCategory = "t_bg";
export var articleContainer = "t_bh";
export var gatsbyImg = "t_bj";
export var cardContent = "t_bk";