import DOMPurify from 'isomorphic-dompurify';
import parseHTML from '../../../utilities/parseHTML';
import { filterAndSortRelatedPosts } from '../../../utilities/gridCardFilters';
import processHeadArgs from '../../common/process/processHeadArgs';
import { titleRelated, btnLabel } from '../Constants';

const processCategoryArgs = (queried) => 
{
    const { category, featuredPost } = queried;
    const categoryArgs = {
        titleRelated,
        btnLabel,
        listLinks: [],
    };
    categoryArgs.postsRelated = filterAndSortRelatedPosts(
        featuredPost ? featuredPost.databaseId : 0,
        [...category.posts.nodes.map((node) => ({ node })), ...category.guides.nodes.map((node) => ({ node }))],
        [],
        true,
    );
    let featuredCard = {};
    if (featuredPost) 
    {
        const { title, uri: url } = featuredPost;
        featuredCard = {
            title: DOMPurify.sanitize(title),
            url: DOMPurify.sanitize(url),
            author: DOMPurify.sanitize(
                featuredPost.author?.node?.cdmfields?.authorDisplay?.displayname
            ),
            minutes: DOMPurify.sanitize(
                featuredPost.cdmfields?.readingtime?.minutes
            ),
            image: featuredPost.featuredImage
                ? {
                      alt: DOMPurify.sanitize(
                          featuredPost.featuredImage.node.altText || title
                      ),
                      data:
                          featuredPost.featuredImage.node.localFileHero
                              .childImageSharp.data,
                  }
                : null,
        };
    }
    else 
    {
        const {
            title,
            author,
            link: url,
            readingtime: minutes,
            featuredImage,
        } = categoryArgs.postsRelated.shift();
        featuredCard = {
            title: DOMPurify.sanitize(title),
            author: DOMPurify.sanitize(author),
            url: DOMPurify.sanitize(url),
            minutes: DOMPurify.sanitize(minutes),
            image: featuredImage
                ? {
                      alt: DOMPurify.sanitize(
                        featuredImage.node.altText || title
                      ),
                      data: featuredImage.node.localFileHero.childImageSharp.data,
                  }
                : null,
        };
    }
    categoryArgs.infoSection = {
        title: DOMPurify.sanitize(
            category.cdmfields?.onpage?.title || category.name
        ),
        description: parseHTML(category.description),
        featuredCard,
        categoryLinks: [],
    };
    categoryArgs.breadcrumbs = category.cdmfields.breadcrumb.map((crumb) => ({
        id: crumb.id,
        term_id: crumb.term_id,
        text: DOMPurify.sanitize(crumb.text),
        url: DOMPurify.sanitize(crumb.url),
    }));
    return categoryArgs;
};
const processQueried = (queried, pageContext) => 
{
    const allArgs = {
        categoryArgs: processCategoryArgs(queried),
        headArgs: processHeadArgs(
            queried.category.cdmfields.metadata,
            queried.site.siteMetadata,
            pageContext
        ),
    };
    return allArgs;
};
export default processQueried;
