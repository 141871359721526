import React from 'react';
import PropTypes from 'prop-types';
import { testAttributes } from './Constants';
import { build } from '../../../Constants.json';

import {
    breadcrumbContainer,
    CategoryComponent,
    // content,
    titlePostRelated,
    gridContainerPostRelated,
} from './CategoryPage.module.scss';
import HeroSection from '../../composite/HeroSection';
import HeroLink from '../../composite/HeroLink';
import GridContainer from '../../imported/GridContainer';
import handlePageChange from '../../../utilities/handlePageChange';
import Breadcrumb from '../../simple/Breadcrumb';

const fnisMainCategory = (links) => links.length > 0;
/**
 Component that allows generate statci page for category pages.
 */
const fngetkey = (index) => `key_${index}`;

export const fngetMorePosts = (posts, index, newIndex) =>
    posts.slice(index, newIndex);

const CategoryPage = ({ categoryInfo, currentPage }) => 
    (
        <div
            className={`${CategoryComponent}    `}
            data-test={testAttributes.CATEGORYPAGE_MAIN}
        >
            <div className={breadcrumbContainer}>
                <Breadcrumb
                    breadcrumbs={categoryInfo.breadcrumbs}
                    data-test={testAttributes.CATEGORYPAGE_BREADCRUMBS}
                    className="breadcrumb"
                />
            </div>

            <HeroSection
                isMainCategory={fnisMainCategory(categoryInfo.listLinks)}
                isFeaturedManually
                infoSection={categoryInfo.infoSection}
                data-test={testAttributes.CATEGORYPAGE_HEROSECTION}
            >
                {categoryInfo.listLinks.map((link, index) => (
                    <HeroLink
                        title={link.title}
                        subtitle={link.subtitle}
                        url={link.url}
                        key={fngetkey(index)}
                    />
                ))}
            </HeroSection>
            <h2
                data-test={testAttributes.CATEGORYPAGE_TITLERELATED}
                className={titlePostRelated}
            >
                {categoryInfo.titleRelated}
            </h2>

            {categoryInfo.postsRelated.length > 0 && <GridContainer
                queryArgs={{
                    posts: categoryInfo.postsRelated,
                    total_found: categoryInfo.postsRelated.length,
                }}
                type="number"
                gridLimit={build.defaults.POSTS_PER_PAGE}
                getMorePosts={fngetMorePosts}
                btnLabel={categoryInfo.btnLabel}
                handlePageChange={handlePageChange}
                data-test={testAttributes.CATEGORYPAGE_GRIDCONTAINER}
                className={gridContainerPostRelated}
                currentPage={currentPage}
                containerId={build.defaults.GRID_CONTAINER_ID}
            />}
        </div>
    );
CategoryPage.propTypes = {
    /** postInfo, data for GraphQL  */
    categoryInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    /** current page for pagination */
    currentPage: PropTypes.number
};

CategoryPage.defaultProps = {
    currentPage: 1
};
export default CategoryPage;
