const imgSrc = 'https://loremflickr.com/1200/675/dog';
// eslint-disable-next-line import/prefer-default-export
export const testAttributes = {
    HEROSECTION_CONTAINER: 'herosection_container',
    HEROSECTION_CONTENT: 'herosection_content',
    HEROSECTION_CONTENT_CARD: 'herosection_content_card',
    HEROSECTION_CONTENT_SUBCATEGORY: 'herosection_content_subcategory',
    HEROTITLE_TITLE: 'herotitle_title',
    HEROTITLE_DESCRIPTION: 'herotitle_description',
    HEROCARD_IMAGE: 'herocard_image',
    HEROCARD_CONTAINER: 'herocard_container',
    HEROCARD_LINK: 'herocard_link',
    HEROCARD_CONTENT: 'herocard_content',
    HEROCARD_CONTENT_TITLE: 'herocard_content_title',
    HEROCARD_CONTENT_AUTHOR: 'herocard_content_author',
    HEROCARD_CONTENT_READTIME: 'herocard_content_readtime',
};
export const defaultAttributes = {
    LABEL: 'Editorial Disclosure',
};

const sampleImage = {
    data: {
        layout: 'constrained',
        backgroundColor: '#f8f8f8',
        images: {
            fallback: {
                src: imgSrc,
                srcSet: `${imgSrc} 64w`,
                sizes: '(min-width: 800px) 800px, 100vw',
            },
            sources: [
                {
                    srcSet: `${imgSrc} 64w`,
                    type: 'image/jpeg',
                    sizes: '(min-width: 800px) 800px, 100vw',
                },
            ],
        },
        width: 1200,
        height: 600,
    },
    alt: 'alt 1',
};
export const sampleAttributes = {
    infoHome: {
        title: 'Learning Center',
        description: undefined,
        featuredCard: {
            title: 'Don’t Miss Out On These Hidden Auto Insurance Discounts',
            author: 'Chester Author',
            minutes: 6,
            url: 'https://www.lowermybills.com/',
            image: sampleImage,
        },
        categoryLinks: [],
    },
    infoCategory: {
        title: 'Auto Insurance Learning Center',
        description: 'Learn About Auto Insurance',
        featuredCard: {
            title: 'Don’t Miss Out On These Hidden Auto Insurance Discounts',
            author: 'Chester Author',
            minutes: 6,
            url: 'https://www.lowermybills.com/',
            image: sampleImage,
        },
        categoryLinks: [],
    },
};
